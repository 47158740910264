import Link from 'next/link';
import dynamic from 'next/dynamic';

import { LogoWrapper } from './style';
import { IImage } from '../common';

const Loader = dynamic(() => import('../../components/Loader'), {
  loading: () => <p>....Loading.....</p>,
});

const BasicImage = dynamic(() => import('../BasicImage'), {
  loading: () => <Loader isLoading />,
});

export interface ILogoProps {
  data: IImage;
}

const Logo = (data: IImage) => {
  const { url, title,width,height } = data;
  return url && title ? (
    <Link href="/" css={LogoWrapper}>
        <BasicImage url={url} title={title}  width={width} height={height}/>
    </Link>
  ) : (
    <span>&nbsp;</span>
  );
};

export default Logo;
